<template>
  <div class="k-9sptci" v-loading="loading">
    <el-form
      :inline="true"
      class="k-9sptci-header"
      :model="searchForm"
      ref="searchForm"
    >
      <el-form-item>
        <el-button type="success" @click="searchClick">查询</el-button>
      </el-form-item>
      <perContainer :perm="`match:save`">
        <el-form-item>
          <el-button type="primary" @click="add">新增</el-button>
        </el-form-item>
      </perContainer>
    </el-form>
    <el-table :data="dataTable" style="margin-top:20px" border>
      <template v-for="item in dataList">
        <el-table-column
          v-if="item.prop == 'image'"
          :key="item._id"
          :prop="item.prop"
          :type="item.type"
          :label="item.label"
          :width="item.width"
          :formatter="item.formatter"
        >
          <template slot-scope="scope">
            <img :src="scope.row.image" style="width: 200px; height: 100px" />
          </template>
        </el-table-column>
        <el-table-column
          v-else
          :key="item._id"
          :prop="item.prop"
          :type="item.type"
          :label="item.label"
          :width="item.width"
          :formatter="item.formatter"
        >
        </el-table-column>
      </template>
      <el-table-column label="操作" fixed="right">
        <template slot-scope="scope">
          <perButton
            :perm="`match:save`"
            @click="handleEdit(scope.row)"
            style="margin-left: 10px"
            >编辑</perButton
          >
          <popconfirmButton
            :perm="`match:save`"
            content="是否确认删除？"
            text="删除"
            @onConfirm="handleDelete(scope.row)"
            style="margin-left: 10px"
          ></popconfirmButton>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      :title="bannerData.view_type == 'add' ? '新增Banner' : '修改Banner'"
      :visible.sync="bannerVisible"
      v-if="bannerVisible"
      :close-on-click-modal="false"
      v-loading="loading"
      width="550px"
    >
      <el-form ref="bannerForm" :model="bannerData" label-width="140px">
        <el-form-item
          label="排序"
          prop="sort"
          :rules="[
            {
              required: true,
              message: '请输入排序',
              trigger: ['blur', 'change'],
            },
            {
              validator: validatorInteger,
              message: '请输入大于0的整数',
              trigger: ['change', 'blur'],
            },
          ]"
        >
          <el-input
            type="number"
            v-model="bannerData.sort"
            placeholder="请输入排序"
            style="width: 200px"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="图片"
          prop="image"
          :rules="[
            {
              required: true,
              message: '请上传图片',
              trigger: ['blur', 'change'],
            },
          ]"
        >
          <el-upload
            class="avatar-uploader"
            :show-file-list="false"
            :before-upload="beforeImageUpload"
            action="#"
            :http-request="httpUploadRequest"
          >
            <img
              v-if="bannerData.image"
              :src="bannerData.image"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item
          label="公众号跳转链接"
          prop="link"
          :rules="[
            {
              required: false,
              message: '请输入跳转链接',
              trigger: ['blur', 'change'],
            },
          ]"
        >
          <el-input
            type="text"
            v-model="bannerData.link"
            placeholder="请输入跳转链接"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="官网文章链接"
          prop="official_link"
          :rules="[
            {
              required: false,
              message: '请输入跳转链接',
              trigger: ['blur', 'change'],
            },
          ]"
        >
          <el-input
            type="text"
            v-model="bannerData.official_link"
            placeholder="请输入跳转链接"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="saveBanner">确定</el-button>
          <el-button @click="bannerVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import perButton from "@/components/perm/perButton";
import { randomStr } from "@/utils/randomStr";
import { uploadOssUrl } from "@/utils/uploadUrl";
export default {
  name: "s1_banners",
  components: {
    perButton,
  },
  computed: {
    ...mapState("match", ["match_types"]),
  },
  data() {
    return {
      ids: [],
      type_id: "s1",
      match_id: "",
      loading: false,
      searchForm: {},
      dataTable: [],
      dataList: [
        {
          prop: "sort",
          label: "排序",
          width: 300,
        },
        {
          prop: "image",
          label: "Banner",
          width: 300,
        },
      ],
      bannerVisible: false,
      bannerData: {
        view_type: "add",
        id: "",
        sort: "",
        image: "",
        link: [],
        official_link: "",
      },
      match: false,
    };
  },
  methods: {
    beforeImageUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG/PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }

      return isJPG && isLt2M;
    },
    async httpUploadRequest(item) {
      this.loading = true;
      let fileName = this.match_id + "_" + randomStr() + ".jpg";

      let path = uploadOssUrl().banners + fileName;

      let result = await this.$http.uploadFile(path, fileName, item.file);
      if (result) {
        this.bannerData.image = result;
      } else {
        this.$message.error("上传失败");
      }
      this.loading = false;
    },
    validatorInteger(rule, value, callback) {
      if (!value.length || !value.trim().length) {
        callback();
        return;
      }

      if (isNaN(Number.parseInt(value))) {
        callback(new Error("请输入大于0的整数"));
      }

      if (Number.isInteger(Number(value)) && Number(value) > 0) {
        callback();
      } else {
        callback(new Error("请输入大于0的整数"));
      }
    },
    add() {
      this.bannerData.view_type = "add";
      this.bannerData.id = "";
      this.bannerData.sort = "";
      this.bannerData.image = "";
      this.bannerData.link = "";
      this.bannerData.official_link = "";

      this.bannerVisible = true;
    },
    handleEdit(row) {
      this.bannerData.view_type = "edit";
      this.bannerData.id = row.id;
      this.bannerData.sort = row.sort;
      this.bannerData.image = row.image;
      this.bannerData.link = row.link;
      this.bannerData.official_link = row.official_link;
      this.bannerVisible = true;
    },
    saveBanner() {
      this.$refs.bannerForm.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          let saveData = {
            match_id: this.match_id,
            id: this.bannerData.id,
            sort: Number.parseInt(this.bannerData.sort + ""),
            image: this.bannerData.image,
            link: this.bannerData.link,
            official_link: this.bannerData.official_link,
          };

          let { data, errorCode } = await this.$http.match.match_save_banner(
            saveData
          );
          this.loading = false;

          if (errorCode != "0000") {
            return;
          }

          this.$message({
            type: "success",
            message: "保存成功",
          });

          this.bannerVisible = false;
          this.dataTable = data;
        }
      });
    },
    async handleDelete(row) {
      this.loading = true;
      let { data, errorCode } = await this.$http.match.match_delete_banner({
        id: row.id,
        match_id: this.match_id,
      });
      this.loading = false;

      if (errorCode != "0000") {
        return;
      }

      this.$message({
        type: "success",
        message: "删除成功",
      });

      this.dataTable = data;
    },
    searchClick() {
      this.$refs.searchForm.validate((v) => {});
      this.handleQuery();
    },
    async handleQuery() {
      this.loading = true;
      const { data, errorCode } = await this.$http.match.match_banners({
        ...this.searchForm,
        match_id: this.match_id,
      });
      this.loading = false;
      if (errorCode != "0000") return;
      this.dataTable = data;
    },
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
  async mounted() {
    this.$store.commit("tabs/setVNode", this.$vnode);
    this.match_id = this.$route.query._id;
    this.handleQuery();
  },
  destroyed() {},
};
</script>
<style lang="less" scoped>
.k-9sptci-header {
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
  .el-form-item {
    margin-bottom: 0;
  }
}
.k-9sptci-footer {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
}

/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 200px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

/deep/ .avatar-uploader-icon {
  font-size: 38px;
  color: rgb(0, 153, 255);
  text-align: center;
}

.avatar {
  width: 200px;
  height: 100px;
}
</style>
